import React from "react";
import Layout from "../components/Layout";
import {Link} from "gatsby";
import Dibujo3d from "../../static/images/solutions/linea-de-peletizacion/c1.png";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/solutions/linea-de-peletizacion/1.jpg)`,
                        }}
                    >
                        <h1>Total Solución para la Granulación de PET Reciclado (Aplicación Industrial)</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Antecedentes</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Cree que las aplicaciones de recursos renovables por
                                indigenización es la forma más ecológica, y maximiza el valor comercial debido a la
                                prohibición de China de la importación de rPET.<br/>
                                <br/>
                                Hacer chips de rPET es una excelente opción, pero los chips de rPET son
                                diferentes de los copos (escamas) de rPET, que no pueden ser un proceso
                                secundario. Por lo tanto, cómo fabricar chips de buena calidad estable es
                                muy importante. Zion Tech es su mejor socio en el suministro de líneas de
                                granulación de virutas rPET de alto rendimiento y buena calidad.
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Diagrama de flujo del proceso</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Alimentación por vacío Separación magnética → Sistema de secado → Alimentación por vacío
                                → Separación de metales → Alimentación volumétrica → Composición de tornillo simple /
                                doble → Filtración de fusión → Cabezal de matriz → Enfriamiento → Peletización
                                subacuática → Secado → Clasificación → Empaque
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Opciones de modelo</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <img src={Dibujo3d} style={{width: "100%"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Service_one
